@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '../assets/fonts/inter/inter.scss';

$azure: #048aef;
$almost-black: #292a2c;
$charcoal: #424242;
$input-grey: #343537;
$dark-slate-blue-two: #234369;
$charcoal-grey: #393c40;
$orange: #f88024;
$very-light-pink-two: #eaeaea;
$very-light-pink-three: #cccccc;
$pale-grey-three: #f3f5f7;
$dark-sky-blue: #4a90e2;
$butterscotch: #ffaf3b;
$butterscotch-two: #ffbf3b;
$orangey-red: #ff423b;
$tealish: #23bed0;
$pumpkin-orange: #fa7209;
$light-grey-blue: #aaaeb3;
$viridian: #269358;
$blue-grey: #838b9b;
$detect: #f6932e;
$identify: #429bd4;
$protect: #774987;
$respond: #e83f4a;
$recover: #3eb34f;
// $pastel-orange: #ff944e;
$pastel-orange: #f76c00;
$light-blue: #add8e6;

$dark-green: #197d74;
$light-orange: rgba(255, 255, 255, 0.55);
//$orangish: #D0250D;
$orangish: #ff7f3b;
$grey: #8e9197;
$brownish-grey: #626262;
$light-burgundy: #c6305a;
$dark-grey: #171818;
$white: #ffffff;
$greyish-brown: #4a4a4a;
$light-grey: #f6f8f8;
$medium-grey: #dddddd;
$warm-grey: #9b9b9b;
$cool-grey: #89969f;
$bluey-grey: #acb4c6;
$pale-grey: #f1f2f5;
$elastic-grey: #d3dae6;
$pale-grey-two: #f3f4f6;
$dark: #2d3547;
$black: #000000;
$silver: #d5dde0;
$silver-two: #d8dbdf;
$silver-three: #bcc8cf;
$cloudy-blue: #bfc5d2;
$red: #d50000;
$coral: #ff4c3b;

html,
body,
#root,
.app-wrapper {
  height: 100%;
  color: $charcoal-grey;
  background: $white;
}

body {
  //font-family: 'Open Sans', "Arial";
  // font-family: 'Source Sans Pro', 'Roboto', 'Arial';
  font-family: 'Inter', 'Source Sans Pro', 'Roboto', 'Arial';
  //font-family: "Roboto";
  //font-family: 'Arial';
}

.page-wrapper {
  flex: 1 1;
  width: 100%;
  top: 60px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  &.full-page-content {
    // padding: 25px;
    width: auto;
    left: 6px;
    right: 6px;
    top: 90px;
    background: $white;
  }
  &.no-header {
    top: 0;
    z-index: 25;
    background: white;
  }
}

.page-content {
  flex: 1 1;
  width: 100%;
}

.full-page-tile {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  .full-page-tile-content {
    width: 100%;
    padding: 15px;
    flex: 1 1;
    background: $white;
    margin: 6px;
  }
}
.breadcrumbs-bar-container {
  //background: $pale-grey-three;
  //background: $white;
  background: $azure;
  color: $white;
  //opacity: 0.5;
  //background: $dark-slate-blue;
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  border-bottom: 1px solid $very-light-pink-two;
  height: 45px;
  padding: 0 7px;
  z-index: 11;
}
.tab-details-container {
  position: absolute;
  left: 180px;
  right: 0;
  padding: 6px 6px 0 6px;
  top: 45px;
  background: $pale-grey-three;
  // bottom: 6px;
  &.no-left-sidebar {
    left: 0;
  }
  &.has-right-sidebar {
    right: 257px;
  }
  &.no-filter-bar {
    top: 0;
  }
  .full-page-tile {
    .full-page-tile-content {
      margin: 0;
      margin-bottom: 6px;
    }
  }
}
.sidebar-content-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  background: $pale-grey-three;
}
.section-title {
  font-size: 18px;
  margin: 10px 0 20px 0;
}
.section-subtitle {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 10px;
}
.secondary-text {
  word-break: break-word;
  font-size: 13px;
  line-height: 1.4;
  color: $charcoal-grey;
}
h2 {
  font-size: 18px;
  font-weight: normal;
}
.btn {
  text-align: center;
  font-size: 14px;
  // padding: 10px 30px;
  padding: 6px 24px;
  text-transform: uppercase;
  font-weight: 600;

  border-radius: 40px;
  background-color: $pastel-orange;
  background-image: linear-gradient(
    to top left,
    transparent 0% 50%,
    rgba(255, 255, 255, 0.14) 50% 100%
  );
  //background: $pastel-orange;
  border: none;
  color: $white;
  text-decoration: none;
  text-transform: capitalize;
  display: block;
  cursor: pointer;
  &:active {
    outline: none;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &.btn-table {
    display: inline-flex;
    margin: 0 7px;
  }
  &.btn-primary {
    &:hover {
      background-color: $pastel-orange;
      box-shadow: 0 4px 10px 0 rgba(135, 167, 171, 0.5);
    }
    &:active,
    &:focus {
      background-color: $pastel-orange;
      box-shadow: 0 0 0 0.2rem $light-grey-blue;
    }
    &:disabled {
      background: $warm-grey;
    }
  }
}
.btn-secondary {
  color: $pastel-orange;
  background: transparent;
  border: 1px solid $pastel-orange;
  font-weight: normal;
  &:active,
  &:focus {
    color: $pastel-orange;
    background: transparent;
    border: 1px solid $pastel-orange;
    box-shadow: none;
  }
  &:hover {
    background-color: $pastel-orange;
    background-image: linear-gradient(
      to top left,
      transparent 0% 50%,
      rgba(255, 255, 255, 0.14) 50% 100%
    );
    box-shadow: 0 4px 10px 0 rgba(135, 167, 171, 0.5);
    color: $white;
    border: 1px solid $pastel-orange;
  }
}
.multi-btn {
  color: $azure;
  //border: 1px solid $azure;
  color: $pastel-orange;
  border: 1px solid $pastel-orange;
  background: $white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 35px;
  &.active {
    color: $white;
    //background: $azure;
    background: $pastel-orange;
  }
  &.btn-left {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  &.btn-right {
    border-left: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}

.btn-danger {
  background: $red;
  color: $white;
  border: 1px solid $red;
}
.btn-negative {
  //background: $silver;
  background: $white;
  color: $warm-grey;
  border: 1px solid $warm-grey;
  &:hover {
    color: $white;
    background: $silver;
  }
}
.btn-text {
  color: $white;
  font-size: 12px;
  text-transform: capitalize;
  text-decoration: none;
  border: 0;
  padding: 0;
  background: none;
  &:hover {
    color: $pastel-orange;
    text-decoration: underline;
    // font-weight: bold;
  }
  &.hover-underline {
    text-decoration: none;
    &:hover {
      color: $pastel-orange;
      text-decoration: underline;
    }
  }
}
.btn-text-black {
  color: $almost-black;
  font-size: 13px;
  text-transform: capitalize;
  text-decoration: underline;
  border: 0;
  padding: 0;
  background: none;
  &:hover {
    color: $pastel-orange;
    text-decoration: underline;
    // font-weight: bold;
  }
  &.hover-underline {
    text-decoration: none;
    &:hover {
      color: $pastel-orange;
      text-decoration: underline;
    }
  }
}
.link-as-btn {
  display: inline;
}
.btn-text-primary {
  color: $pastel-orange;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 600;
  border: 0;
  padding: 0;
  background: none;
}
.bolden {
  font-weight: bold;
}

.btn-bare {
  background: none;
  border: none;
  padding: 0;
}
.btn-material-ui {
  background: none;
  border: none;
  padding: 0;
  //color: $pastel-orange;
  color: $white;
  svg {
    stroke: $pastel-orange;
  }
  &:hover {
    background: $white;
    color: $pastel-orange;
    fill: $pastel-orange;
  }
}
.btn-icon {
  color: $pastel-orange;
  &:hover {
    color: $pastel-orange;
  }
}
.remove-item-btn {
  background: $pastel-orange;
  &:hover {
    color: $pastel-orange;
    fill: $white;
    stroke: $white;
  }
}

.btn-copy {
  background: none;
  border: none;
  padding: 0;
  color: $black;
  &:hover {
    color: $pastel-orange;
  }
}
.input-container {
  margin-bottom: 24px;
  width: 100%;
  > .input-field {
    width: 100%;
  }
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'] {
    border: none;
    // border: 1px solid rgba(4, 138, 239, 0.3);
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    ::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
    &:focus {
      border: 1px solid $azure;
      outline: none;
    }
  }
  > .input-validation-error {
    color: $red;
    font-size: 12px;
    margin-top: 5px;
    text-align: left;
  }
  &.invalid {
    > input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='number'] {
      border-bottom: 1px solid $red;
    }
  }
}

.form-error-message {
  color: $red;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}
.form-info {
  color: $light-grey-blue;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.2;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.df-card {
  // box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
  box-shadow: none;
  border: 1px solid $elastic-grey;
  border-radius: 4px;
  background: $white;
  .df-card-body {
    margin-top: 20px;
  }
}
.single-card-row {
  .padded-card {
    &:nth-of-type(odd) {
      padding-right: 0;
    }
  }
}
.row-of-cards.smaller-cards {
  .padded-card {
    height: 250px;
    flex: 1 1 50%;
  }
}

.padded-card {
  height: 400px;
  padding-bottom: 7px;
  background: transparent;
  flex: 1 1 50%;
  &:nth-of-type(odd) {
    padding-right: 7px;
  }
  .card-contents {
    //border-radius: 7px;
    //box-shadow: 0 2px 6px 0 rgba(191, 197, 210, 0.4);
    // box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    box-shadow: none;
    border: 1px solid $elastic-grey;
    border-radius: 4px;
    background-color: $white;
    flex: 1 1;
    position: relative;
    .card-header {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background: $white;
      color: $black;
      // color: $blue-grey;
      text-align: left;
      font-size: 14px;
      padding: 10px 0;
      margin: 5px 20px 10px 15px;
      border-bottom: none;
      //border-bottom: 1px solid $very-light-pink-two;
    }
    .card-body {
      position: absolute;
      overflow: auto;
      top: 35px;
      left: 0;
      right: 0;
      bottom: 0;
      flex: 1 1;
      padding: 10px 20px;
      .empty-message-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 13px;
      }
    }
  }
}
.zero-state-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  max-width: 1000px;
  .zero-state-message {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.4;
  }
  .zero-state-submessage {
    margin-bottom: 20px;
    color: $blue-grey;
    font-size: 14px;
    line-height: 1.2;
  }
  .actions-container {
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
}
.inline-kv-container {
  margin-bottom: 7px;
  .inline-key {
    word-break: break-all;
    font-size: 13px;
    font-weight: 600;
    color: $dark-slate-blue-two;
    margin-right: 5px;
  }
  .inline-value {
    word-break: break-word;
    color: $charcoal-grey;
    font-size: 13px;
  }
}

.actions-container {
  margin: 15px auto;
  width: 100%;
  &.left-aligned {
    margin-left: 0;
    width: auto;
  }
  .action-btn {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.indicator-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $orange;
  &.active {
    background: $viridian;
  }
  &.inactive {
    background: $respond;
  }
}
.form-submit-error {
  color: $respond;
  font-size: 13px;
  margin: 5px 0;
  text-align: center;
  font-weight: 800;
  line-height: 1.4;
  .btn-text {
    color: $respond;
  }
}
.error-color {
  color: $respond;
}

@media screen and (max-width: '1020px') {
  .app-wrapper {
    display: none;
  }
  .page-wrapper {
    top: 66px;
  }
  .unsupported-browser-size {
    display: block;
  }
}
