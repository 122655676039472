@import '../../styles/index.scss';

.header-container {
  height: 60px;
  width: 100%;
  background: $orange;
  margin-bottom: 2px;
  position: fixed;
  z-index: 20;

  .header-logo {
    margin-left: 24px;
    margin-right: 75px;
    width: 180px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  .header-user-container {
    padding-right: 25px;
    flex: 1 1;
  }
  .header-links-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .pages-link-container {
      padding: 0;
      margin: 0 25px 0 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      .page-link-container {
        height: 100%;
        display: flex;
        align-items: center;

        &.df-smaller-device {
          display: none;
        }

        .page-link-item {
          height: 100%;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #ffffff;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 2px;
          position: relative;

          &::after {
            content: '';
            background-color: #1f2022;
            right: auto;
            position: absolute;
            height: 3px;
            width: 100%;
            left: 0;
            z-index: 2;
            bottom: 0;
            visibility: hidden;
          }

          &:hover {
            color: $almost-black;
            &::after {
              visibility: visible;
            }
          }

          &.active {
            color: $almost-black;
            &::after {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .mobile-header-link-actions {
    display: none;
  }
}

@media screen and (max-width: '1020px') {
  .company-summary-container {
    display: none;
  }

  .header-container {
    height: auto;
    padding: 16px;

    .header-logo {
      margin: 0;
    }

    .mobile-header-link-actions {
      display: block;

      .device-icon {
        display: none;
        color: #ffffff;
        font-size: 30px;
        font-weight: bolder;
        cursor: pointer;

        &:focus {
          border: 1px $white;
        }

        &.device-icon-active {
          display: block;
          color: $white;

          &:hover {
            color: $almost-black;
          }
        }
      }
    }

    .header-links-actions {
      display: none;

      &.df-navbar-open {
        display: block;

        .pages-link-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
      }

      .pages-link-container {
        background: $orange;
        position: fixed;
        width: 100%;
        top: 60px;
        left: 0;

        .page-link-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: auto;
          width: 100%;

          &.df-smaller-device {
            display: flex;
          }

          .page-link-item {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1.5px;
            text-decoration: none;

            &::after {
              display: none;
            }

            &:hover {
              color: $almost-black;
            }
          }
        }
      }
    }

    .header-user-container {
      display: none;
    }
  }
}
