@import '../../styles/index.scss';

.company-summary-container {
  width: 60%;
  height: 100%;
  padding-left: 90px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .df-brand-logo {
    display: flex;
  }

  .company-summary {
    color: $white;

    .company-headline,
    .company-headline-2 {
      color: $almost-black;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      padding-right: 25px;
    }
    .company-headline {
      font-size: 17px;
      font-weight: 600;
    }
    .summary {
      margin-top: 25px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      padding-left: 20px;
      color: $almost-black;

      ul {
        list-style: disc;
      }
    }

    .df-brand-line {
      margin-top: 40px;
      width: 100%;
      display: flex;
      flex-direction: row;

      .df-thick-line {
        height: 5px;
        flex: 1 1;
        background: $pastel-orange;
      }

      .df-thin-line {
        flex: 1 1;
        height: 2px;
        background: $pastel-orange;
      }
    }
  }
}
