@import './index.scss';

.loggedout-form-container {
  width: 40%;
  height: 100%;
  background: $charcoal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loggedout-form {
    width: 80%;
    margin: 0 auto;

    .loggedout-form-block {
      display: flex;
    }

    .extra-options {
      font-weight: 600;
      margin-top: 10px;
    }

    :first-child.loggedout-form-field {
      margin-right: 10px;
    }

    .loggedout-form-field {
      flex: 1 1;
    }

    label {
      margin-bottom: 10px;
      font-size: 13px;
    }
  }

  .section-title {
    text-transform: uppercase;
    color: $almost-black;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 4px;
    line-height: 28px;
    margin-bottom: 25px;
    color: $white;
    text-align: center;
  }
  .actions-container {
    margin-top: 30px;
    .action-btn {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: '1020px') {
  .loggedout-form-container {
    width: 100%;

    .loggedout-form {
      .loggedout-form-block {
        flex-direction: column;
      }

      :first-child.loggedout-form-field {
        margin: 0;
      }
    }

    .actions-container {
      &.actions-reverse-column {
        flex-direction: column-reverse !important;

        .action-btn {
          margin-bottom: 20px;
        }
      }
    }
  }
}
