@import '../../../styles/index.scss';

.df-form {
  .input-container {
    margin-bottom: 5px;

    label {
      font-size: 12px;
      font-weight: 600;
    }

    input {
      min-height: 40px;
      border-radius: 0px !important;

      background-color: $input-grey;
      border-color: $input-grey;
      border-width: 0px 0px 0px 0px;
      border-radius: 0px 0px 0px 0px;
      color: white;

      padding: 8px 16px;

      &::placeholder {
        color: white;
        font-size: 14px;
        opacity: 0.6;
        font-weight: 400;
      }

      &:active,
      &:focus {
        border: 0 !important;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 1000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }

    .invalid-feedback {
      margin: 10px 0;
      font-size: 1em;
      line-height: 1;
    }
  }
}

.df-light-input {
  .input-container {
    input {
      border-radius: 0px;
      background: $very-light-pink-two;
      color: black;

      &::placeholder {
        color: $almost-black;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 1000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }
}
