@import '../../styles/index.scss';

.reset-password-link-expired {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 50%;
  padding-top: 45px;
  margin: 0 auto;
  border-radius: 10px;

  .section-title {
    text-transform: uppercase;
    color: #292a2c;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 4px;
    line-height: 28px;
    margin-bottom: 25px;
  }

  .link-expired-message {
    font-size: 16px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: '1020px') {
  .reset-password-link-expired {
    text-align: center;
  }
}
