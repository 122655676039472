@import './index.scss';

.modal-content {
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  border: none;
}
.custom-modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 20px;
  //background-color: $azure;
  background: $dark-sky-blue;
  .modal-title {
    color: $white;
    text-transform: capitalize;
  }
  .close {
    color: $white;
    opacity: 1;
    font-size: 35px;
    font-weight: normal;
  }
}

.custom-modal-body {
  padding: 0;
  .modal-sidebar-container {
    width: 180px;
    border-right: 1px solid $very-light-pink-two;
    padding: 15px;
    .modal-sidebar-link {
      width: 100%;
      color: black;
      margin-bottom: 25px;
      text-decoration: none;
      font-size: 14px;
      text-align: left;
      padding: 0;
      padding-bottom: 7px;
      border-radius: 0;
      border-bottom: 1px solid $very-light-pink-two;
      &.active {
        color: $azure;
        border-bottom: 2px solid $azure;
      }
    }
  }
  .tab-content {
    flex: 1 1;
    padding: 15px;
  }
}
.custom-dialog-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  .custom-dialog-top-bar {
    position: relative;
    box-shadow: none;
    background: $azure;
    color: $white;
    border-bottom: 1px solid #eaeaea;
    height: 50px;
    padding: 0;
    .custom-dialog-toolbar {
      height: 100%;
      min-height: 100%;
    }
  }
  .custom-dialog-content {
    flex: 1 1;
    background: $pale-grey-three;
    padding: 6px;
  }
  .custom-dialog-form {
    padding: 15px;
    margin-top: 10px;
    flex: 1 1;
  }
  .dialog-form-input {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 992px) {
  .modal-dialog {
    max-width: 90%;
  }
}