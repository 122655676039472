@import 'styles/index.scss';
.df-loader-graph {
  max-width: 400px;
  min-width: 200px;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.df-loader-table {
  border-radius: 7px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.df-loader-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
}

.df-loader-logo {
  -webkit-animation: KEYFRAME-NAME 1s infinite;
  -moz-animation: KEYFRAME-NAME 1s infinite;
  -o-animation: KEYFRAME-NAME 1s infinite;
  animation: KEYFRAME-NAME 1s infinite;
}

@-webkit-keyframes KEYFRAME-NAME {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes KEYFRAME-NAME {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@-o-keyframes KEYFRAME-NAME {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes KEYFRAME-NAME {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
