.Toastify__toast-container {
  font-family: 'Inter', 'Source Sans Pro', 'Roboto', 'Arial';
  font-size: 14px;

  .Toastify__toast {
    // min-height: 48px;
  }

  .Toastify__toast--error {
    color: #fff;
    font-weight: 500;
    background-color: #f44336;
  }

  .Toastify__toast--success {
    color: #fff;
    font-weight: 500;
    background-color: #4caf50;
  }
}
